import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertContext } from "contexts/AlertContext";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlerDisplay(): JSX.Element {
  const alertContext = useContext(AlertContext);

  const hideAlert = () => {
    alertContext?.dispatchAlert({
      type: "UNSET_ALERT",
      value: null,
    });
  };
  return (
    <Snackbar
      open={alertContext?.alertState.active}
      autoHideDuration={3000}
      onClose={hideAlert}
      action={
        <React.Fragment>
          {alertContext?.alertState.showUndo && (
            <Button
              color="secondary"
              size="small"
              onClick={alertContext?.alertState.onUndo}
            >
              UNDO
            </Button>
          )}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={hideAlert}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    >
      <Alert onClose={hideAlert} severity={alertContext?.alertState.severity}>
        {alertContext?.alertState.message}
      </Alert>
    </Snackbar>
  );
}

export default AlerDisplay;
