import { createContext } from "react";

export enum AlertSeverity {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
}

interface IAlertState {
  active: boolean;
  message: string;
  onUndo?: () => void;
  showUndo: boolean;
  severity?: AlertSeverity;
}

export enum AlertContextActions {
  SET_ALERT = "SET_ALERT",
  UNSET_ALERT = "UNSET_ALERT",
}

interface IAction {
  type: AlertContextActions;
  value: IAlertState | null;
}

interface IAlertContext {
  alertState: IAlertState;
  dispatchAlert: CallableFunction;
}

export const AlertContext = createContext<IAlertContext | undefined>(undefined);

export const AlertReducer = (
  state: IAlertState,
  action: IAction
): IAlertState => {
  switch (action.type) {
    case "SET_ALERT": {
      return {
        ...state,
        ...action.value,
      };
    }

    case "UNSET_ALERT": {
      return {
        active: false,
        message: "",
        onUndo: () => null,
        showUndo: false,
      };
    }

    default:
      return state;
  }
};
