import { retrieveFromLocalStore } from "utils/funcs";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
// --------------
const baseURL = process.env.REACT_APP_API;
const httpLink = createHttpLink({
  uri: `${baseURL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Accept-Language": "en",
      Authorization: `Bearer ${retrieveFromLocalStore("access_token")}`,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
// ------------------
