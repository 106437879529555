export function storeInLocalStore(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function retrieveFromLocalStore(key: string): string | null {
  const value = localStorage.getItem(key);
  return value;
}

export function isElement(obj: any): boolean {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return (
      typeof obj === "object" &&
      obj.nodeType === 1 &&
      typeof obj.style === "object" &&
      typeof obj.ownerDocument === "object"
    );
  }
}

export function toLocalDatetime(dt: string): string {
  const ldt = new Date(dt);
  return ldt.toString();
}
