import React, { Suspense, useReducer } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  HomePage,
  AdvertisersPage,
  BannersPage,
  LoginPage,
  LogoutPage,
  LogsListPage,
  LogsItemPage,
  GroupsListPage,
  ResourcesPage,
  GroupsCreatePage,
  PermissionsCreatePage,
  PermissionsListPage,
  PermissionsUpdatePage,
  UsersListPage,
  UsersCreatePage,
  UsersUpdatePage,
  CountriesListPage,
  StatesListPage,
  SpecialisationsListPage,
  CountriesCreatePage,
  StatesCreatePage,
  CountriesUpdatePage,
  SpecialisationsCreatePage,
} from "pages";
import TopProgressBar from "components/TopProgressBar";
import { ApolloProvider } from "@apollo/client";
import PrivateRoute from "components/PrivateRoute";
import { UserContext, UserReducer } from "contexts/UserContext";
import {
  AlertContext,
  AlertReducer,
  AlertSeverity,
} from "contexts/AlertContext";
import { client } from "services/client";
import AlerDisplay from "containers/AlertDisplay";

function App(): JSX.Element {
  const [userState, dispatchUser] = useReducer(UserReducer, {
    id: "",
    group: "",
    user_name: "",
  });

  const [alertState, dispatchAlert] = useReducer(AlertReducer, {
    active: false,
    message: "",
    onUndo: () => null,
    showUndo: false,
    severity: AlertSeverity.success,
  });

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<TopProgressBar />}>
        <AlertContext.Provider value={{ alertState, dispatchAlert }}>
          <UserContext.Provider value={{ userState, dispatchUser }}>
            <Router>
              <Switch>
                <Route path="/advertisers">
                  <AdvertisersPage />
                </Route>
                <Route path="/banners">
                  <BannersPage />
                </Route>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <PrivateRoute path="/logs/:logId" exact={true}>
                  <LogsItemPage />
                </PrivateRoute>
                <PrivateRoute path="/logs" exact={true}>
                  <LogsListPage />
                </PrivateRoute>
                <PrivateRoute path="/users" exact={true}>
                  <UsersListPage />
                </PrivateRoute>
                <PrivateRoute path="/users/create" exact={true}>
                  <UsersCreatePage />
                </PrivateRoute>
                <PrivateRoute path="/users/:userId/update" exact={true}>
                  <UsersUpdatePage />
                </PrivateRoute>
                <PrivateRoute path="/permissions" exact={true}>
                  <PermissionsListPage />
                </PrivateRoute>
                <PrivateRoute path="/permissions/create" exact={true}>
                  <PermissionsCreatePage />
                </PrivateRoute>
                <PrivateRoute
                  path="/permissions/:permissionId/update"
                  exact={true}
                >
                  <PermissionsUpdatePage />
                </PrivateRoute>
                <PrivateRoute path="/groups" exact={true}>
                  <GroupsListPage />
                </PrivateRoute>
                <PrivateRoute path="/groups/create" exact={true}>
                  <GroupsCreatePage />
                </PrivateRoute>
                <PrivateRoute path="/resources" exact={true}>
                  <ResourcesPage />
                </PrivateRoute>
                <PrivateRoute path="/masterlists/countries" exact={true}>
                  <CountriesListPage />
                </PrivateRoute>
                <PrivateRoute path="/masterlists/countries/create" exact={true}>
                  <CountriesCreatePage />
                </PrivateRoute>
                <PrivateRoute
                  path="/masterlists/countries/:masterlistCountryId/update"
                  exact={true}
                >
                  <CountriesUpdatePage />
                </PrivateRoute>
                <PrivateRoute path="/masterlists/states" exact={true}>
                  <StatesListPage />
                </PrivateRoute>
                <PrivateRoute path="/masterlists/states/create" exact={true}>
                  <StatesCreatePage />
                </PrivateRoute>
                <PrivateRoute path="/masterlists/specialisations" exact={true}>
                  <SpecialisationsListPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/masterlists/specialisations/create"
                  exact={true}
                >
                  <SpecialisationsCreatePage />
                </PrivateRoute>
                <PrivateRoute path="/logout" exact={true}>
                  <LogoutPage />
                </PrivateRoute>
                <PrivateRoute path="/" exact={true}>
                  <HomePage />
                </PrivateRoute>
              </Switch>
            </Router>
          </UserContext.Provider>
          <AlerDisplay />
        </AlertContext.Provider>
      </Suspense>
    </ApolloProvider>
  );
}

export default App;
