import { RouteProps } from "react-router";
import { useQuery } from "@apollo/client";
import { VALIDATE } from "services/queries";
import { Redirect, Route } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "contexts/UserContext";
import { AlertContext, AlertSeverity } from "contexts/AlertContext";
import { retrieveFromLocalStore } from "utils/funcs";
import FullPageLoader from "components/FullPageLoader";

interface IPrivateProps extends RouteProps {
  path: string;
}

function PrivateRoute(props: IPrivateProps): JSX.Element {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);

  const { loading, data } = useQuery(VALIDATE, {
    pollInterval: retrieveFromLocalStore("access_token") ? 30000 : undefined,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      userContext?.dispatchUser({
        type: "SET_USER",
        value: {
          ...data?.validate?.user,
        },
      });
    }
  }, [data]);
  if (data && data?.validate?.error) {
    alertContext?.dispatchAlert({
      type: "SET_ALERT",
      value: {
        active: true,
        message: data?.validate?.error,
        showUndo: false,
        severity: AlertSeverity.error,
      },
    });
    localStorage.removeItem("access_token");
    return <Redirect to="/login" />;
  }

  if (loading) {
    return <FullPageLoader />;
  }

  const renderComponent = () => <Redirect to={{ pathname: props.path }} />;
  return <Route {...props} component={renderComponent} />;
}

export default PrivateRoute;
