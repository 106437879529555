import { gql } from "@apollo/client";

export const VALIDATE = gql`
  query Validate {
    validate {
      error
      user {
        id
        group
        user_name
      }
    }
  }
`;

export const LOGS = gql`
  query Logs($start: String, $stop: String, $perPage: Int, $reverse: Boolean) {
    logs(start: $start, stop: $stop, limit: $perPage, reverse: $reverse) {
      error
      logs {
        id
        user_id
        resolver
        payload
        response_time
        created_at
      }
      total_count
    }
  }
`;

export const LOG = gql`
  query Log($logId: String!) {
    log(id: $logId) {
      error
      log {
        id
        user_id
        resolver
        payload
        response_time
        response
        created_at
      }
    }
  }
`;

export const GROUPS = gql`
  query Groups($offset: Int, $limit: Int) {
    groups(offset: $offset, limit: $limit) {
      error
      groups {
        id
        group
      }
      total_count
    }
  }
`;

export const RESOURCES = gql`
  query Resources($offset: Int, $limit: Int) {
    resources(offset: $offset, limit: $limit) {
      error
      resources {
        id
        resource_table
        resource_name
      }
      total_count
    }
  }
`;

export const PERMISSIONS = gql`
  query Permissions(
    $offset: Int
    $limit: Int
    $group: String
    $resource: String
  ) {
    permissions(
      offset: $offset
      limit: $limit
      group: $group
      resource: $resource
    ) {
      error
      total_count
      permissions {
        id
        group
        resource
        create
        read
        update
        delete
      }
    }
  }
`;

export const PERMISSION = gql`
  query Permission($id: UUID!) {
    permission(id: $id) {
      error
      permission {
        id
        group
        resource
        create
        read
        update
        delete
      }
    }
  }
`;

export const USERS = gql`
  query Users($offset: Int!, $limit: Int!, $group: String, $user_name: String) {
    users(
      offset: $offset
      limit: $limit
      group: $group
      user_name: $user_name
    ) {
      error
      users {
        id
        user_name
        group
      }
      total_count
    }
  }
`;

export const USER = gql`
  query User($id: UUID) {
    user(id: $id) {
      error
      user {
        id
        user_name
        group
      }
    }
  }
`;

export const MASTERLIST_COUNTRIES = gql`
  query MastertlistCountries(
    $offset: Int
    $limit: Int
    $country: String
    $locale: String
  ) {
    masterlist_countries(
      offset: $offset
      limit: $limit
      country: $country
      locale: $locale
    ) {
      error
      masterlist_countries {
        id
        country
        locale
      }
      total_count
    }
  }
`;

export const MASTERLIST_STATES = gql`
  query MastertlistStates(
    $offset: Int
    $limit: Int
    $countryId: UUID
    $locale: String
    $state: String
  ) {
    masterlist_states(
      offset: $offset
      limit: $limit
      country_id: $countryId
      locale: $locale
      state: $state
    ) {
      error
      masterlist_states {
        id
        country_id
        locale
        state
      }
      total_count
    }
  }
`;

export const MASTERLIST_SPECIALISATIONS = gql`
  query MasterlistSpecialisations(
    $offset: Int
    $limit: Int
    $majorClassification: String
    $middleClassification: String
    $subCategory: String
    $locale: String
  ) {
    masterlist_specialisations(
      offset: $offset
      limit: $limit
      major_classification: $majorClassification
      middle_classification: $middleClassification
      sub_category: $subCategory
      locale: $locale
    ) {
      error
      total_count
      masterlist_specialisations {
        id
        major_classification
        middle_classification
        sub_category
        specific_category
        locale
        created_at
        updated_at
      }
    }
  }
`;

export const MASTERLIST_COUNTRY = gql`
  query MasterlistCountry($id: UUID!) {
    masterlist_country(id: $id) {
      error
      masterlist_country {
        id
        country
        locale
      }
    }
  }
`;
