import { lazy } from "react";

const Home = lazy(() => import("pages/Home"));
const Advertisers = lazy(() => import("pages/Advertisers"));
const Banners = lazy(() => import("pages/Banners"));
const Login = lazy(() => import("pages/Login"));
const Logout = lazy(() => import("pages/Logout"));
const LogsList = lazy(() => import("pages/Logs/ListView"));
const LogsItem = lazy(() => import("pages/Logs/ItemView"));
const GroupsList = lazy(() => import("pages/Groups/ListView"));
const GroupsCreate = lazy(() => import("pages/Groups/CreateView"));
const Resources = lazy(() => import("pages/Resources"));
const PermissionsList = lazy(() => import("pages/Permissions/ListView"));
const PermissionsCreate = lazy(() => import("pages/Permissions/CreateView"));
const PermissionsUpdate = lazy(() => import("pages/Permissions/UpdateView"));
const UsersList = lazy(() => import("pages/Users/ListView"));
const UsersCreate = lazy(() => import("pages/Users/CreateView"));
const UsersUpdate = lazy(() => import("pages/Users/UpdateView"));
const CountriesList = lazy(() => import("pages/Countries/ListView"));
const StatesList = lazy(() => import("pages/States/ListView"));
const SpecialisationsList = lazy(
  () => import("pages/Specialisations/ListView")
);
const CountriesCreate = lazy(() => import("pages/Countries/CreateView"));
const StatesCreate = lazy(() => import("pages/States/CreateView"));
const CountriesUpdate = lazy(() => import("pages/Countries/UpdateView"));
const SpecialisationsCreate = lazy(
  () => import("pages/Specialisations/CreateView")
);

export {
  Home as HomePage,
  Advertisers as AdvertisersPage,
  Banners as BannersPage,
  Login as LoginPage,
  Logout as LogoutPage,
  LogsList as LogsListPage,
  LogsItem as LogsItemPage,
  GroupsList as GroupsListPage,
  Resources as ResourcesPage,
  GroupsCreate as GroupsCreatePage,
  PermissionsCreate as PermissionsCreatePage,
  PermissionsList as PermissionsListPage,
  PermissionsUpdate as PermissionsUpdatePage,
  UsersList as UsersListPage,
  UsersCreate as UsersCreatePage,
  UsersUpdate as UsersUpdatePage,
  CountriesList as CountriesListPage,
  StatesList as StatesListPage,
  SpecialisationsList as SpecialisationsListPage,
  CountriesCreate as CountriesCreatePage,
  StatesCreate as StatesCreatePage,
  CountriesUpdate as CountriesUpdatePage,
  SpecialisationsCreate as SpecialisationsCreatePage,
};
