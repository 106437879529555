import styles from "./FullPageLoader.module.scss";

function FullPageLoader(): JSX.Element {
  return (
    <div className={styles.main}>
      <div className={styles["lds-roller"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {/* <div className={styles.loading}>Loading</div> */}
    </div>
  );
}

export default FullPageLoader;
