import { createContext } from "react";

interface IUserState {
  id: string;
  group: string;
  user_name: string;
}

export enum UserContextActions {
  SET_USER = "SET_USER",
  UNSET_USER = "UNSET_USER",
}

interface IAction {
  type: UserContextActions;
  value: IUserState;
}

interface IUserContext {
  userState: IUserState;
  dispatchUser: CallableFunction;
}
export const UserContext = createContext<IUserContext | undefined>(undefined);

export const UserReducer = (state: IUserState, action: IAction): IUserState => {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        ...action.value,
      };
    }

    case "UNSET_USER": {
      return {
        id: "",
        group: "",
        user_name: "",
      };
    }

    default:
      return state;
  }
};
